import HomePage from "./Home/home";
import "bootstrap/dist/css/bootstrap.css";
import "./Assets/styles/styles.scss";
import "./Assets/styles/media.scss";
import { useEffect, useState } from "react";

const URL = process.env.REACT_APP_SOCKET_URL;

const socket = new WebSocket(URL);

const App = () => {
  const [solarData, setSolarData] = useState({});
  const [graphData, setGraphData] = useState({});
  const [conntecting, setConnecting] = useState(false);

  const getUpdateData = () => {
    setInterval(() => {
      const msgData = {
        event: "get_data",
      };
      socket.send(JSON.stringify(msgData));
    }, 5000);
  };

  useEffect(() => {
    if (conntecting) {
      getUpdateData();
    }
  }, [conntecting]);

  socket.onopen = (event) => {
    setConnecting(true);
    const msgData = {
      event: "get_data",
    };
    socket.send(JSON.stringify(msgData));
  };

  socket.onclose = () => {};

  socket.onmessage = (event) => {
    const data = event.data;
    const parsedData = JSON.parse(data);
    const solar_data = parsedData.solar_data;
    const energy_data = parsedData.graph_data;
    setSolarData(solar_data);
    setGraphData(energy_data);
  };

  return (
    <div className="App">
      <HomePage solarData={solarData} graphData={graphData} />
    </div>
  );
};

export default App;
