import React from "react";

export const BatteryIcon = (props) => {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_177_363)">
        <path
          d="M9.92767 4.41297C9.93641 3.25038 9.27207 2.33255 7.95214 2.46367C7.56753 2.49863 7.37522 2.39374 7.44515 1.97416C7.45389 1.92171 7.44515 1.86052 7.44515 1.79933C7.43641 1.09129 7.16543 0.820312 6.46613 0.820312C5.50459 0.820312 4.54305 0.820312 3.58151 0.820312C2.81228 0.820312 2.55879 1.07381 2.55879 1.8343C2.55879 2.45493 2.55879 2.45493 1.93816 2.45493C0.723121 2.46367 0.09375 3.09304 0.09375 4.31682V9.81507C0.09375 11.6595 0.09375 13.5126 0.09375 15.357C0.09375 16.5284 0.731862 17.1665 1.89445 17.1665C3.91368 17.1665 5.94165 17.1228 7.96088 17.1839C9.22837 17.2189 9.93641 16.3972 9.91893 15.2434C9.8927 11.6332 9.8927 8.02311 9.92767 4.41297ZM7.36648 9.19444C6.59725 10.7242 5.82802 12.2626 5.05879 13.7923C5.03256 13.7836 5.00634 13.7749 4.98011 13.7661C4.98011 12.8483 4.97137 11.9392 4.98886 11.0214C4.9976 10.7242 4.92767 10.6193 4.61298 10.628C3.94865 10.6542 3.28431 10.6367 2.5413 10.6367C3.36298 8.99339 4.14969 7.41996 4.93641 5.83779C4.96263 5.84654 4.9976 5.85528 5.02382 5.85528C5.02382 6.77311 5.03256 7.69968 5.01508 8.61751C5.00634 8.90598 5.09375 8.98465 5.37347 8.97591C5.96788 8.95842 6.56228 8.96716 7.16543 8.96716C7.31403 8.97591 7.4976 8.94968 7.36648 9.19444Z"
          fill="#FEE89F"
        />
      </g>
      <defs>
        <clipPath id="clip0_177_363">
          <rect
            width="9.83392"
            height="16.3636"
            fill="white"
            transform="translate(0.09375 0.820312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
