import React from "react";
import { Spinner } from "react-bootstrap";

const LoadSpinner = (props) => {
  const { className } = props || {};
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${className}`}
    >
      <Spinner animation="border" role="status" />
      <span className="px-2">Loading...</span>
    </div>
  );
};

export default LoadSpinner;
