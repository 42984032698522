import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: true,
      //   text: "Chart.js Bar Chart",
    },
    datalabels: {
      anchor: "end",
      align: "end",
      font: {
        size: 14,
        weight: "bold",
      },
      color: "white",
      formatter: (value) => (value ? `${value} wh` : "0 wh"),
    },
  },
  scales: {
    x: {
      display: true,
      grid: {
        drawBorder: false, // <-- this removes y-axis line
        lineWidth: 0,
      },
    },
    y: {
      display: false,
      grid: {
        drawBorder: false, // <-- this removes y-axis line
        lineWidth: 0,
      },
    },
  },
};

export const EnergyChart = (props) => {
  const { graphData } = props || {};
  //   const labels = ["08:00", "09:00", "10:00", "11:00", "12:00", "01:00"];

  const labels = graphData && Object.keys(graphData).slice(0, 6).reverse();
  const energyValue =
    graphData &&
    Object.values(graphData)
      .slice(0, 6)
      .reverse()
      .map((element) => (element ? parseFloat(element).toFixed(2) : 0));

  const data = {
    labels,
    datasets: [
      {
        // label: "",
        data: energyValue,
        backgroundColor: `rgba(86, 98, 245, 1)`,
        barPercentage: 0.85,
        categoryPercentage: 0.85,
      },
    ],
  };
  return <Bar options={options} data={data} />;
};
