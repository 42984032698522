import { BatteryIcon } from "../Assets/icons/BatteryIcon";
import { PowerIcon } from "../Assets/icons/PowerIcon";
import logo from "./../Assets/images/logo.png";
import flashLense from "./../Assets/images/white-lense.svg";
import campassImg from "./../Assets/images/campass-frame.svg";
import sunImg from "./../Assets/images/sun-image.svg";
import { useEffect, useState } from "react";
import { EnergyChart } from "../components/Chart";
import moment from "moment";
import LoadSpinner from "../components/Spinner";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

const HomePage = (props) => {
  const { solarData, graphData } = props || {};
  const [azimuth, setAzimuth] = useState(solarData.azimuth);
  const [loading, setLoading] = useState(true);

  const updatedTime = solarData.time;
  const lastUpdatedAt = updatedTime
    ? moment(updatedTime, "YYYYMMDDHHmmss").fromNow()
    : "";

  const formattedDate = updatedTime
    ? moment(updatedTime, "YYYYMMDDHHmmss").format("DD/MMM/YYYY")
    : "";
  const formattedTime = updatedTime
    ? moment(updatedTime, "YYYYMMDDHHmmss").format("HH:mm:ss")
    : "";

  useEffect(() => {
    if (solarData) {
      setLoading(false);
      setAzimuth(solarData.azimuth);
    }
  }, [solarData]);

  if (loading) {
    return <LoadSpinner />;
  }

  const darkMapStyles = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#242f3e",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#242f3e",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#746855",
        },
      ],
    },
  ];

  const currentTime = moment().format("DD/MMM/YYYY HH:mm:ss");

  return (
    <>
      <div className="main min-vh-100 d-flex justify-content-center flex-row w-100">
        <div className="page-wrapper primary-bg px-sm-4 px-3 py-xl-5 py-sm-4 py-3 w-100">
          <header className=" top-0 primary-bg z-index-99">
            <div className="header-wrapper position-relative">
              <div className="logo-wrapper">
                <a href="" className="position-relative z-index-99">
                  <img
                    className="logo"
                    src={logo}
                    width={239}
                    height={72}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="time-wrapper d-flex align-items-center flex-column gap-sm-2 gap-1 position-absolute top-0 bottom-0 justify-content-center">
                <div className="time-div text-center">
                  <h5 className="white-font font-we-sb d-inline-block fw-normal">
                    Last message received
                  </h5>
                </div>
                <div className="d-flex align-items-center gap-sm-3 gap-2">
                  <div className="time-div text-center">
                    <h3 className="op-5 white-font mb-2 d-inline-block fw-normal">
                      Time
                    </h3>
                    <h1 className="primary-font font-we-sb">{formattedTime}</h1>
                  </div>
                  <div className="date-div text-center">
                    <h3 className="op-5 white-font mb-2 d-inline-block fw-normal">
                      Date
                    </h3>
                    <h1 className="white-font font-we-sb">{formattedDate}</h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="content">
            <div className="d-flex flex-column justify-content-between gap-xl-4 gap-3 h-100">
              <div className="row inner-content1 gap-lg-0 gap-3">
                <div className="col-lg-7 col-12">
                  <div className="secondary-bg custom-rounded card h-100">
                    <h1 className="white-font font-we-nr  pb-2 pt-xl-2">
                      <span className="op-5">Solar Unit ID:</span>{" "}
                      {solarData.unit}
                    </h1>
                    <h5 className="white-font op-5 font-we-nr">
                      {`Updated ${lastUpdatedAt}`}
                    </h5>
                    <div className="row align-items-center justify-content-between d-flex card1">
                      <div className="col-xl-7 col-sm-8 col-12">
                        <div className="row g-sm-3 g-2 mt-1">
                          <div className="col-12">
                            <div className="primary-bg-shade rounded py-xl-4 py-3 px-xl-4 px-3 d-flex align-items-center gap-3 ">
                              <h4 className="white-font fw-normal op-5">
                                Status:
                              </h4>
                              <h1 className="white-font font-we-sb">Active</h1>
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="primary-bg-shade rounded py-xl-4 py-3 ps-xl-4 ps-3 pe-4 position-relative">
                              <div className="secondary-bg icon-wrapper rounded-circle d-flex align-items-center justify-content-center position-absolute ">
                                <span className="primary-font">
                                  <BatteryIcon />
                                </span>
                              </div>
                              <div className="py-2">
                                <h4 className="white-font fw-normal op-5 mb-3">
                                  Battery Voltage
                                </h4>
                                <h1 className="white-font font-we-sb">
                                  {`${solarData.batt_volt} Volt`}
                                </h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-12">
                            <div className="primary-bg-shade rounded py-xl-4 py-3 ps-xl-4 ps-3 pe-4 position-relative">
                              <div className="secondary-bg icon-wrapper rounded-circle d-flex align-items-center justify-content-center position-absolute ">
                                <span className="primary-font">
                                  <PowerIcon />
                                </span>
                              </div>
                              <div className="py-2">
                                <h4 className="white-font fw-normal op-5 mb-3">
                                  Current Output
                                </h4>
                                <h1 className="white-font font-we-sb">
                                  {`${solarData.output} Watt`}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 col-sm-4 col-12 text-center">
                        <img
                          src={flashLense}
                          alt="flash-lense"
                          width={250}
                          className="flash-lense-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-12">
                  <div className="secondary-bg custom-rounded card d-flex flex-column justify-content-between h-100">
                    <div>
                      <h2 className="white-font font-we-nr pb-2">
                        Energy Generated <span className="fw-light">Wh</span>
                      </h2>
                      <h5 className="white-font op-5 font-we-nr">
                        {`Updated ${lastUpdatedAt}`}
                      </h5>
                    </div>
                    <div className="h-100 mt-4 mb-lg-0 mb-3 d-flex">
                      <div className="barchart">
                        {graphData && <EnergyChart graphData={graphData} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row inner-content2 gap-lg-0 gap-3">
                <div className="col-lg-7 col-12">
                  <div className="secondary-bg custom-rounded p-xl-4 p-3 h-100">
                    <div className="d-flex justify-content-between flex-sm-row flex-column gap-sm-0 gap-3">
                      <div>
                        <h2 className="white-font font-we-nr pb-2">
                          Sun’s Position
                        </h2>
                        <h5 className="white-font op-5 font-we-nr">
                          {`Updated ${lastUpdatedAt}`}
                        </h5>
                      </div>
                      <div className="d-flex  gap-xl-3 gap-2">
                        <div className="primary-bg-shade py-2 px-3 d-flex align-items-center gap-2 rounded">
                          <h4 className="white-font font-we-nr op-5">
                            Azimuth
                          </h4>
                          <h3 className="white-font font-we-nr">
                            {solarData.azimuth}
                          </h3>
                        </div>
                        <div className="primary-bg-shade py-2 px-3 d-flex align-items-center gap-2 rounded">
                          <h4 className="white-font font-we-nr op-5">Elev</h4>
                          <h3 className="white-font font-we-nr angle-set">
                            {solarData.elev} <sup></sup>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="campass-img d-flex mx-auto justify-content-center my-lg-0 my-3 position-relative">
                      <div
                        style={{
                          transform: `rotate(${azimuth}deg)`,
                        }}
                      >
                        <h5
                          className="text-white angle-set position-absolute start-0 end-0 mx-auto text-center"
                          style={{
                            transform: `rotate(-${azimuth}deg)`,
                          }}
                        >
                          Elev {Math.round(solarData.elev)} <sup></sup>
                        </h5>
                      </div>
                      <div
                        style={{
                          transform: `rotate(${azimuth}deg)`,
                        }}
                      >
                        <img
                          src={sunImg}
                          alt="sun-image"
                          className="sun-image"
                        />
                      </div>
                      <img src={campassImg} alt="campass-img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-12">
                  <div className="secondary-bg custom-rounded p-xl-4 p-3 h-100">
                    <div className="d-flex justify-content-between flex-sm-row flex-column gap-sm-0 gap-3 align-items-sm-center align-items-start">
                      <div>
                        <h2 className="white-font font-we-nr">
                          Solar Unit Position
                        </h2>
                        <h5 className="white-font op-5 font-we-nr">
                          {`Updated ${lastUpdatedAt}`}
                        </h5>
                      </div>
                      <div className="d-flex gap-xl-3 gap-2">
                        <div className="primary-bg-shade py-2 px-3 d-flex align-items-center gap-2 rounded">
                          <h4 className="white-font font-we-nr op-5">LAT</h4>
                          <h3 className="white-font font-we-nr">
                            {solarData.lat}
                          </h3>
                        </div>
                        <div className="primary-bg-shade py-2 px-3 d-flex align-items-center gap-2 rounded">
                          <h4 className="white-font font-we-nr op-5">LON</h4>
                          <h3 className="white-font font-we-nr">
                            {solarData.lon}
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className="text-center pt-3 rounded-3 map-view">
                      {solarData.lat && solarData.lon && (
                        <APIProvider
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        >
                          <Map
                            center={{
                              lat: solarData.lat,
                              lng: parseFloat(solarData.lon),
                            }}
                            zoom={4}
                            // styles={darkMapStyles}
                          >
                            <Marker
                              position={{
                                lat: solarData.lat,
                                lng: parseFloat(solarData.lon),
                              }}
                            />
                          </Map>
                        </APIProvider>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black fixed-bottom text-end footer-content">
        <h4 className="white-font d-inline-block fw-normal p-2 pe-5">
          Current Date & Time : {currentTime}
        </h4>
      </div>
    </>
  );
};

export default HomePage;
