import React from "react";

export const PowerIcon = (props) => {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.57078 17.18C5.48453 17.1799 5.39896 17.1648 5.31789 17.1354C5.1615 17.0782 5.02886 16.9701 4.9412 16.8285C4.85354 16.6869 4.81594 16.5199 4.83442 16.3544L5.40714 11.0809H1.02617C0.891045 11.0811 0.758416 11.0445 0.64253 10.975C0.526643 10.9055 0.431876 10.8058 0.368406 10.6865C0.304937 10.5672 0.275164 10.4328 0.282283 10.2979C0.289403 10.163 0.333147 10.0325 0.408818 9.92057L6.27739 1.14375C6.3702 1.0064 6.50624 0.90399 6.66391 0.852783C6.82157 0.801576 6.99182 0.804505 7.14763 0.861106C7.29742 0.916715 7.42531 1.01908 7.51239 1.15304C7.59946 1.287 7.64109 1.44544 7.6311 1.6049L7.05837 6.91562H11.4393C11.5745 6.91542 11.7071 6.95203 11.823 7.02152C11.9389 7.09101 12.0336 7.19076 12.0971 7.31005C12.1606 7.42935 12.1904 7.56367 12.1832 7.69861C12.1761 7.83355 12.1324 7.964 12.0567 8.07595L6.18813 16.8528C6.1199 16.9537 6.02792 17.0363 5.92028 17.0934C5.81263 17.1505 5.69261 17.1802 5.57078 17.18Z"
        fill="currentColor"
      />
    </svg>
  );
};
